import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Select } from 'antd';
import { MappingContext } from 'context';
import { useTranslation } from 'react-i18next';

import { NakedButton } from 'components/UI';
import { COLUMN_TYPES } from 'constants/gridConstants';
import {
  useGetProductCustomFieldsQuery,
  useGetStoreCustomFieldsQuery,
  EntityTypeEnum,
  ArticleTypeEnum,
  SalesTypeEnum,
  SizeTypeEnum,
  StoreStockTypeEnum,
  StoreTypeEnum,
  SupplierStockTypeEnum,
  SupplierTypeEnum,
  WarehouseStockEnumType,
  WarehouseTypeEnum,
  IdealStockTypeEnum,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { StoreCustomFields, ProductCustomFields } from './types';

type Props = {
  fileHeaders: { label: string; value: number }[];
  mappingType: string;
  matchedData: { columns: { index: number; name: string }[] | null; name: string }[];
  setMatchedData: (data: { columns: { index: number; name: string }[] | null; name: string }[]) => void;
};

const MappingTable: React.FC<Props> = ({ fileHeaders, mappingType, setMatchedData }) => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();
  const gridRef = useRef<AgGridReact>(null);
  const { universeAvailable, assignSizeSystemFromFamily, currentMappings, requiredFields } = useContext(MappingContext);

  const [rowData, setRowData] = useState<
    { import_data?: { index: number; name: string } | null; matchedValue?: string | null; name: string }[]
  >([]);
  const [mandatoryFields, setMandatoryFields] = useState<string[]>([]);
  const [mappingSchema, setMappingSchema] = useState<
    | typeof ArticleTypeEnum
    | typeof SalesTypeEnum
    | typeof SizeTypeEnum
    | typeof StoreStockTypeEnum
    | typeof StoreTypeEnum
    | typeof SupplierStockTypeEnum
    | typeof SupplierTypeEnum
    | typeof WarehouseStockEnumType
    | typeof WarehouseTypeEnum
    | typeof IdealStockTypeEnum
  >();
  const [customFieldNames, setCustomFieldNames] = useState<{ label: string; name?: string | null }[]>();

  const { data: dataProductCustomFields } = useGetProductCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const { data: dataStoreCustomFields } = useGetStoreCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const getMappingTypeSchema = useCallback(
    (mappingType: string) => {
      const mappingTypeKey = Object.keys(EntityTypeEnum).find(
        (key) => EntityTypeEnum[key as keyof typeof EntityTypeEnum] === mappingType,
      );
      mappingTypeKey && setMandatoryFields(requiredFields[mappingTypeKey as keyof typeof EntityTypeEnum]);

      switch (mappingTypeKey) {
        case 'Article':
          return ArticleTypeEnum;
        case 'Sale':
          return SalesTypeEnum;
        case 'Size':
          return SizeTypeEnum;
        case 'StoreStock':
          return StoreStockTypeEnum;
        case 'Store':
          return StoreTypeEnum;
        case 'SupplierStock':
          return SupplierStockTypeEnum;
        case 'Supplier':
          return SupplierTypeEnum;
        case 'WarehouseStock':
          return WarehouseStockEnumType;
        case 'Warehouse':
          return WarehouseTypeEnum;
        case 'IdealStock':
          return IdealStockTypeEnum;
      }
    },
    [requiredFields],
  );

  useEffect(() => {
    if (mappingType) {
      if (mappingType === EntityTypeEnum.Store) {
        const customFields = [];
        for (let i = 0; i < 5; i++) {
          if (dataStoreCustomFields?.tenantSettings[`storeCustomFieldName${i}` as keyof StoreCustomFields]) {
            customFields.push({
              label: `CustomFieldCode${i}`,
              name: `${
                dataStoreCustomFields?.tenantSettings[`storeCustomFieldName${i}` as keyof StoreCustomFields]
              } ${t('clientDetails.code')}`,
            });
            customFields.push({
              label: `CustomFieldName${i}`,
              name: `${
                dataStoreCustomFields?.tenantSettings[`storeCustomFieldName${i}` as keyof StoreCustomFields]
              } ${t('clientDetails.label')}`,
            });
          }
        }
        setCustomFieldNames(customFields);
      } else if (mappingType === EntityTypeEnum.Article) {
        const customFields = [];
        for (let i = 0; i < 5; i++) {
          if (dataProductCustomFields?.tenantSettings[`productCustomFieldName${i}` as keyof ProductCustomFields]) {
            customFields.push({
              label: `CustomFieldCode${i}`,
              name: `${
                dataProductCustomFields?.tenantSettings[`productCustomFieldName${i}` as keyof ProductCustomFields]
              } ${t('clientDetails.code')}`,
            });
            customFields.push({
              label: `CustomFieldName${i}`,
              name: `${
                dataProductCustomFields?.tenantSettings[`productCustomFieldName${i}` as keyof ProductCustomFields]
              } ${t('clientDetails.label')}`,
            });
          }
        }
        setCustomFieldNames(customFields);
      } else {
        setCustomFieldNames(undefined);
      }
    }
  }, [mappingType, dataStoreCustomFields, dataProductCustomFields, t]);

  useEffect(() => {
    if (mappingType) {
      const mappingTypeSchema = getMappingTypeSchema(mappingType);
      setMappingSchema(mappingTypeSchema);
      const mappingTypeSchemaKeys = mappingTypeSchema && Object.keys(mappingTypeSchema);
      const alreadyMappedFields = currentMappings.find(
        (obj) =>
          obj.model ===
          Object.keys(EntityTypeEnum).find((key) => EntityTypeEnum[key as keyof typeof EntityTypeEnum] === mappingType),
      )?.fields;

      mappingSchema &&
        mappingTypeSchemaKeys &&
        setRowData(
          mappingTypeSchemaKeys
            .filter((key) => {
              if (
                (key.includes('CustomFieldCode') || key.includes('CustomFieldName')) &&
                !customFieldNames?.find((obj) => obj.label === key)
              ) {
                return;
              }

              return key;
            })
            ?.map((key) => {
              const columns =
                alreadyMappedFields &&
                alreadyMappedFields?.find(
                  (obj) =>
                    key &&
                    key ===
                      Object.keys(mappingSchema)?.find(
                        (schemaKey) => mappingSchema[(schemaKey as keyof typeof mappingSchema) || ''] === obj?.name,
                      ),
                )?.columns;
              const columnName = columns && columns[0]?.name;

              return {
                name: key,
                import_data: undefined,
                matchedValue: columnName,
              };
            }),
        );
    }
  }, [mappingType, customFieldNames, fileHeaders, getMappingTypeSchema, currentMappings, mappingSchema]);

  useEffect(() => {
    let newlyMatchedData: { columns: { index: number; name: string }[] | null; name: string }[] = [];
    if (rowData && mappingSchema) {
      newlyMatchedData = rowData
        .filter((line) => line.import_data !== undefined)
        .map((line) => ({
          columns: line.import_data ? [line.import_data!] : null,
          name: mappingSchema[line.name as keyof typeof mappingSchema],
        }));
      setMatchedData(newlyMatchedData);
    }
  }, [rowData, setMatchedData, mandatoryFields, mappingSchema]);

  const RemoveRenderer = (props: ICellRendererParams) => {
    const handlePrepareRemoveRow = (props: ICellRendererParams) => {
      setRowData(
        rowData.map((obj) => {
          if (obj.name === props.data.name) {
            return { import_data: null, name: props.data.name };
          }

          return obj;
        }),
      );
    };

    return (
      <NakedButton type="text" onClick={() => handlePrepareRemoveRow(props)}>
        <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
      </NakedButton>
    );
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: false,
    };
  }, []);

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: t('clientDetails.label'),
      maxWidth: 250,
      valueFormatter: (params) => {
        if (
          (params.value.includes('CustomFieldCode') || params.value.includes('CustomFieldName')) &&
          customFieldNames
        ) {
          return customFieldNames.find((field) => field.label === params.value)?.name || '';
        }

        return `${t(`clientDetails.${params.value}`)}${mandatoryFields.includes(params.value) ? ' *' : ''}`;
      },
    },
    {
      field: 'import_data',
      headerName: t('clientDetails.import_data'),
      cellRenderer(params: ICellRendererParams) {
        return (
          <Select
            placeholder={t('clientDetails.select_column')}
            disabled={
              !fileHeaders.length ||
              !universeAvailable ||
              (assignSizeSystemFromFamily &&
                (params.data.name === 'SizeSystemCode' || params.data.name === 'SizeSystemName'))
            }
            popupClassName="styledDropdown"
            value={rowData.find((obj) => obj.name === params.data.name)?.import_data?.index}
            onChange={(value: any) => {
              setRowData(
                rowData.map((obj) => {
                  if (obj.name === params.data.name) {
                    return {
                      import_data: { index: value, name: fileHeaders.find((header) => header.value === value)?.label! },
                      name: params.data.name,
                    };
                  }

                  return obj;
                }),
              );
            }}
            style={{ width: '100%' }}
          >
            {fileHeaders?.map((header) => (
              <Select.Option key={header.value} value={header.value}>
                {header.label}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      field: 'matchedValue',
      headerName: t('clientDetails.matched_column'),
    },
    {
      suppressMenu: true,
      cellStyle: { textAlign: 'center' },
      maxWidth: 60,
      cellRenderer: RemoveRenderer,
      pinned: 'right',
    },
  ];

  return (
    <AgGridReact
      containerStyle={{ width: '100%', maxWidth: 840, marginTop: 25, marginBottom: 10, height: 'auto' }}
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      defaultColDef={defaultColDef}
      domLayout={'autoHeight'}
      suppressCellFocus={true}
      singleClickEdit={true}
      suppressRowVirtualisation={true}
      stopEditingWhenCellsLoseFocus={true}
      rowSelection={'single'}
      columnTypes={COLUMN_TYPES}
    />
  );
};

export default MappingTable;
