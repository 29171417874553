import React, { useEffect, useState, useContext } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Upload, Select, Input, Row, Col } from 'antd';
import { TenantsContext, MappingContext } from 'context';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PrimaryButton, Checkbox, InputFormTooltip } from 'components/UI';
import { EntityTypeEnum, useUpdateTenantMutation, CsvMapInput } from 'services/graphql/main';
import { useError } from 'services/utils';

import MappingTable from './MappingTable';
import {
  StyledLabel,
  StyledSwitch,
  SubTitle,
  StyledCheckCircleOutline,
  StyledExclamationCircleOutlined,
  TypeSelectorsWrapper,
} from './styles';

const MappingTool: React.FC = () => {
  const { t } = useTranslation('translation');
  const { code } = useParams();
  const { getTenantDetails, refetch } = useContext(TenantsContext);
  const { assignSizeSystemFromFamily, mappedTypes, requiredFields, currentMappings } = useContext(MappingContext);
  const tenantDetails = code ? getTenantDetails(code) : undefined;
  const { addError, addCustomError } = useError();

  const [mappingType, setMappingType] = useState<string>();
  const [typeNotRequired, setTypeNotRequired] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [fileHeaders, setFileHeaders] = useState<{ label: string; value: number }[]>([]);
  const [matchedData, setMatchedData] = useState<{ columns: { index: number; name: string }[] | null; name: string }[]>(
    [],
  );
  const [createRelatedItems, setCreateRelatedItems] = useState<boolean>(false);
  const [dateFormat, setDateFormat] = useState<string>();
  const [fileMask, setFileMask] = useState<string>();

  const [updateTenant, { loading: mutationLoading, data: mutationData, error: mutationError }] =
    useUpdateTenantMutation({
      onError: (err) => addError(err, 'error'),
    });

  const entityTypes = Object.keys(EntityTypeEnum);

  const resetState = () => {
    setFileHeaders([]);
    setMappingType(undefined);
    setFileMask(undefined);
    setUploadedFile(undefined);
    setTypeNotRequired(false);
  };

  const props: UploadProps = {
    id: 'fileInput',
    maxCount: 1,
    accept: '.csv',
    onRemove: () => {
      setFileHeaders([]);
      setUploadedFile(undefined);
    },
    beforeUpload: (file) => {
      setFileHeaders([]);
      setUploadedFile(file);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          results.meta.fields && setFileHeaders(results.meta.fields.map((field, i) => ({ value: i, label: field })));
        },
      });

      return false;
    },
    fileList: uploadedFile ? [uploadedFile] : [],
  };

  const mapDataHandler = async () => {
    if (tenantDetails) {
      if (
        !requiredFields[mappingType as keyof typeof EntityTypeEnum].every((field) =>
          matchedData.some((entry) => entry.name === field),
        ) &&
        !requiredFields[mappingType as keyof typeof EntityTypeEnum].every((field) =>
          currentMappings
            .find((mapping) => mapping.model === mappingType)
            ?.fields?.some((entry) => entry?.name === field),
        )
      ) {
        addCustomError({ message: t('REQUIRED_FIELDS_NOT_FILLED') }, 'warning');

        return;
      }

      if (typeNotRequired) {
        await updateTenant({
          variables: {
            tenantInput: {
              id: tenantDetails.id,
              importFileMappings: [
                {
                  model: mappingType,
                  isRequired: false,
                  createRelatedItems,
                } as CsvMapInput,
              ],
            },
          },
        });
      } else {
        await updateTenant({
          variables: {
            tenantInput: {
              id: tenantDetails.id,
              assignSizeSystemFromFamily,
              importFileMappings: [
                {
                  model: mappingType,
                  dateFormat,
                  createRelatedItems,
                  fields: matchedData,
                  fileMask,
                  isRequired: true,
                } as CsvMapInput,
              ],
            },
          },
        });
      }

      refetch();
    }
  };

  const checkMappingStatus = (type: string) => {
    return mappedTypes.includes(type);
  };

  useEffect(() => {
    const currentFileMask = currentMappings.find((mapping) => mapping.model === mappingType)?.fileMask;
    mappingType && setFileMask(currentFileMask || `${mappingType}_([\\d_]+)\\.csv`);
  }, [mappingType, currentMappings]);

  useEffect(() => {
    if (!mutationError && mutationData?.updateTenant.importFileMappings) {
      const msg = t('clientDetails.successful_data_mapping');
      toast.success(msg, { theme: 'colored' });
      resetState();
    }
  }, [mutationData, mutationError, t]);

  return (
    <>
      <Row>
        <Col md={12}>
          <TypeSelectorsWrapper>
            <div>
              <StyledLabel htmlFor="typeSelect">{t('clientDetails.mapping_type')}</StyledLabel>
              <Select
                id="typeSelect"
                placeholder={t('clientDetails.select_type')}
                onChange={(value: string) => setMappingType(value)}
                style={{ marginBottom: 8, width: 150 }}
                value={mappingType}
              >
                {entityTypes.map((value: string, i: number) => (
                  <Select.Option key={i} value={EntityTypeEnum[value as keyof typeof EntityTypeEnum]}>
                    {t(`clientDetails.${value}`)}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {mappingType && (
              <div>
                <StyledLabel htmlFor="typeSelect">{t('clientDetails.type_not_required')}</StyledLabel>
                <Checkbox checked={typeNotRequired} onChange={(value) => setTypeNotRequired(value.target.checked)} />
              </div>
            )}
          </TypeSelectorsWrapper>
          {!typeNotRequired ? (
            <>
              <StyledLabel htmlFor="fileInput">{t('clientDetails.file_upload')}</StyledLabel>
              <Upload {...props}>
                <PrimaryButton icon={<UploadOutlined />} text={t('clientDetails.upload_file')} />
              </Upload>
              <StyledLabel htmlFor="date_format" style={{ marginTop: 8 }}>
                {t('clientDetails.date_format')}
              </StyledLabel>
              <Input
                style={{ width: 150 }}
                id="date_format"
                placeholder="yyyymmdd"
                onChange={(e) => setDateFormat(e.target.value)}
              />
              <StyledLabel htmlFor="related_items" style={{ marginTop: 8 }}>
                {t('clientDetails.create_related_items')}
              </StyledLabel>
              <StyledSwitch
                id="related_items"
                checked={createRelatedItems}
                onChange={(value) => setCreateRelatedItems(value)}
              />
              <StyledLabel htmlFor="file_mask" style={{ marginTop: 8 }}>
                {t('clientDetails.file_mask')}
                <InputFormTooltip text="clientDetails.file_mask_tooltip" />
              </StyledLabel>
              <Input
                style={{ width: 300 }}
                id="file_mask"
                onChange={(e) => setFileMask(e.target.value)}
                value={fileMask}
              />
            </>
          ) : (
            <PrimaryButton
              textKey="clientDetails.submit"
              onClick={mapDataHandler}
              style={{ display: 'block' }}
              loading={mutationLoading}
            />
          )}
        </Col>
        <Col md={12}>
          {Object.keys(EntityTypeEnum).map((type: string, i: number) => (
            <p key={i} style={{ margin: 5 }}>
              {checkMappingStatus(type) ? <StyledCheckCircleOutline /> : <StyledExclamationCircleOutlined />}
              {t(`clientDetails.${type}`)}
            </p>
          ))}
        </Col>
      </Row>
      {mappingType && !typeNotRequired ? (
        <div>
          <MappingTable
            fileHeaders={fileHeaders}
            mappingType={mappingType}
            matchedData={matchedData}
            setMatchedData={setMatchedData}
          />

          {uploadedFile && (
            <PrimaryButton
              textKey="clientDetails.submit"
              onClick={mapDataHandler}
              style={{ display: 'block' }}
              loading={mutationLoading}
            />
          )}
        </div>
      ) : (
        !typeNotRequired && <SubTitle style={{ marginTop: 18 }}>{t('clientDetails.select_mapping_type')}</SubTitle>
      )}
    </>
  );
};

export default MappingTool;
