import React, { useState } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Input, Upload, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PrimaryButton } from 'components/UI';
import envconfig from 'config/envconfig';
import { getCacheVal, isTokenCloseToExpiration } from 'services/utils';
import { useError } from 'services/utils';

type NewsInputs = {
  body?: string;
  image?: string;
  subtitle?: string;
  title: string;
};

const News: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError } = useError();
  const [form] = Form.useForm();
  const token = getCacheVal('token');

  const [uploadedImg, setUploadedImg] = useState<any>();
  const [dataUploading, setDataUploading] = useState(false);

  const layout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 14 },
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    accept: '.jpg,.jpeg,.png',
    onRemove: () => {
      setUploadedImg(undefined);
    },
    beforeUpload: (file) => {
      setUploadedImg(file);

      return false;
    },
    fileList: uploadedImg ? [uploadedImg] : [],
  };

  const handleSubmit = async (inputsValues: NewsInputs) => {
    setDataUploading(true);
    const data = new FormData();
    data.append('Title', inputsValues.title);
    inputsValues.subtitle && data.append('Subtitle', inputsValues.subtitle);
    inputsValues.body && data.append('Body', inputsValues.body);
    uploadedImg && data.append('Img', uploadedImg);
    token && isTokenCloseToExpiration(token);
    try {
      await fetch(envconfig.newsUrl, {
        headers: { authorization: `Bearer ${token}` },
        method: 'POST',
        body: data,
      });

      setDataUploading(false);
      const msg = t('news.article_successfully_uploaded');
      toast.success(msg, { theme: 'colored' });
      form.setFieldsValue({ title: undefined, subtitle: undefined, body: undefined });
      setUploadedImg(undefined);
    } catch (err) {
      addCustomError(err, 'error');
      form.setFieldsValue({ title: undefined, subtitle: undefined, body: undefined });
      setUploadedImg(undefined);
      setDataUploading(false);
    }
  };

  return (
    <Form
      {...layout}
      validateMessages={validateMessages}
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ title: '' }}
    >
      <Form.Item rules={[{ required: true }]} label={t('news.title')} name="title">
        <Input id="title" placeholder={t('news.title')} />
      </Form.Item>
      <Form.Item label={t('news.subtitle')} name="subtitle">
        <Input id="subtitle" placeholder={t('news.subtitle')} />
      </Form.Item>
      <Form.Item label={t('news.body')} name="body">
        <Input.TextArea
          rows={10}
          id="body"
          placeholder="Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt..."
        />
      </Form.Item>
      <Form.Item label={t('news.image')} name="image">
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('news.drag_area_placeholder')}</p>
        </Upload.Dragger>
      </Form.Item>

      <PrimaryButton type="primary" textKey="common.save" htmlType="submit" loading={dataUploading} />
    </Form>
  );
};

export default News;
