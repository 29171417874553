import React, { useCallback, useEffect, useState } from 'react';

import { Form, InputNumber, Select } from 'antd';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FullSizeSpinner, PrimaryButton, Spinner } from 'components/UI';
import {
  CapacityType,
  PermutationFieldType,
  TenantSettings,
  useGetTenantSettingsQuery,
  useUpdateOptistocManagmentTenantMutation,
  useUpdateTenantSettingsMutation,
  useGetProductCustomFieldsQuery,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { StyledSection, StyledSwitch, SubTitle } from './styles';
import { FormsValidation, ProductCustomFields } from './types';

const validateMessages = {
  required: '${label} is required!',
};

type OptistockManagmentType = {
  isErpMasterOfCreationAndModification: TenantSettings['isErpMasterOfCreationAndModification'];
  productCoefficient: TenantSettings['productCoefficient'];
  productCoverage: TenantSettings['productCoverage'];
  productMaxStrength: TenantSettings['productMaxStrength'];
  storeAverageStrength: TenantSettings['storeAverageStrength'];
  storeCapacityType: TenantSettings['storeCapacityType'];
  storeCoefficient: TenantSettings['storeCoefficient'];
  storeMaxStrength: TenantSettings['storeMaxStrength'];
  storeNumberOfWeeksStrength: TenantSettings['storeNumberOfWeeksStrength'];
  storePerformancePermutationField1: TenantSettings['storePerformancePermutationField1'];
  storePerformancePermutationField2: TenantSettings['storePerformancePermutationField2'];
};

const OptistockManagement: React.FC<{
  onFormValidation: React.Dispatch<React.SetStateAction<FormsValidation>>;
  tenantId: number;
}> = ({ onFormValidation, tenantId }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const [form] = Form.useForm();
  const [isTenantSaving, setIsTenantSaving] = useState(false);
  const [isTenantSettingsSaving, setIsTenantSettingsSaving] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [masterOfCreationOnly, setMasterOfCreationOnly] = useState(false);

  const masterOfCreationAndModification = form.getFieldValue('isErpMasterOfCreationAndModification');
  const successMessage = () => {
    const msg = t('common.successfully_saved');
    toast.success(msg, { theme: 'colored' });
  };

  const { data, loading, refetch } = useGetTenantSettingsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const { data: dataProductCustomFields } = useGetProductCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [updateTenantSettings, { error: mutationError }] = useUpdateTenantSettingsMutation({
    onError: (err) => addError(err, 'error'),
    onCompleted: () => {
      setIsTenantSettingsSaving(false);

      if (!(isTenantSaving && isTenantSettingsSaving)) {
        setIsSubmitButtonDisabled(true);
        successMessage();
      }
    },
  });

  const [updateTenant] = useUpdateOptistocManagmentTenantMutation({
    onError: (err) => addError(err, 'error'),
    onCompleted: () => {
      setIsTenantSaving(false);

      if (!(isTenantSaving && isTenantSettingsSaving)) {
        setIsSubmitButtonDisabled(true);
        successMessage();
      }
    },
  });

  const isFormValid = useCallback(() => {
    const requiredFields = form.getFieldsValue();
    delete requiredFields.storePerformancePermutationField2;

    return (
      Object.values(requiredFields).filter((el: unknown) => el === null || el === undefined || el === '').length === 0
    );
  }, [form]);

  const capacityTypes = Object.values(CapacityType);
  const permutationFieldTypes = Object.entries(PermutationFieldType)
    .map(([key, value]) => {
      if (
        key !== 'Family' &&
        dataProductCustomFields?.tenantSettings[
          `productCustomFieldName${key.slice(-1)}` as keyof ProductCustomFields
        ] !== null
      ) {
        const label =
          dataProductCustomFields?.tenantSettings[
            `productCustomFieldName${key.slice(-1)}` as keyof ProductCustomFields
          ];

        return { value, label };
      }

      if (key === 'Family') {
        return { value: PermutationFieldType.Family, label: t('clientDetails.family') };
      }
    })
    .filter(Boolean);

  useEffect(() => {
    tenantId && refetch();
  }, [tenantId, refetch]);

  useEffect(() => {
    form.setFieldsValue(data?.tenantSettings);
  }, [form, data?.tenantSettings]);

  useEffect(() => {
    setMasterOfCreationOnly(!form.getFieldValue('isErpMasterOfCreationAndModification'));
  }, [form, masterOfCreationAndModification]);

  useEffect(() => {
    if (data) {
      onFormValidation((prev) => ({
        ...prev,
        optistockManagement: isFormValid(),
      }));
    }
  }, [data, onFormValidation, isFormValid]);

  const handleSubmit = async (inputsValues: OptistockManagmentType) => {
    if (data?.tenantSettings.id) {
      setIsTenantSaving(true);
      setIsTenantSettingsSaving(true);

      await updateTenantSettings({
        variables: {
          tenantSettings: {
            id: data?.tenantSettings.id,
            ...omit(inputsValues, 'isErpMasterOfCreationAndModification'),
            storePerformancePermutationField2: inputsValues.storePerformancePermutationField2
              ? inputsValues.storePerformancePermutationField2
              : null,
          },
        },
      });

      await updateTenant({
        variables: {
          tenantInput: {
            id: tenantId,
            isErpMasterOfCreationAndModification: inputsValues.isErpMasterOfCreationAndModification,
          },
        },
      });
    }

    if (!mutationError) await refetch();
  };

  return !loading && data?.tenantSettings ? (
    <Form
      layout="vertical"
      form={form}
      initialValues={data.tenantSettings}
      validateMessages={validateMessages}
      onFinish={handleSubmit}
      onValuesChange={() => {
        setIsSubmitButtonDisabled(false);

        onFormValidation((forms) => ({
          ...forms,
          optistockManagement: isFormValid(),
        }));
      }}
      style={{ position: 'relative' }}
    >
      {isTenantSaving && isTenantSettingsSaving ? <FullSizeSpinner /> : null}

      <SubTitle>{t('clientDetails.product_settings')}</SubTitle>
      <StyledSection>
        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.product_max_strength')}
          name="productMaxStrength"
        >
          <InputNumber min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.product_coefficient')}
          name="productCoefficient"
        >
          <InputNumber min={0} step={0.1} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} label={t('clientDetails.product_coverage')} name="productCoverage">
          <InputNumber min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.analysis_axis')}
          name="storePerformancePermutationField1"
        >
          <Select placeholder={t('clientDetails.analysis_axis')} style={{ width: '100%' }}>
            {permutationFieldTypes?.map((type) => (
              <Select.Option key={type?.value} value={type?.value}>
                {type?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="storePerformancePermutationField2" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select placeholder={t('clientDetails.analysis_axis')} style={{ width: '100%' }} allowClear>
            {permutationFieldTypes?.map((type) => (
              <Select.Option key={type?.value} value={type?.value}>
                {type?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </StyledSection>

      <SubTitle>{t('clientDetails.store_settings')}</SubTitle>
      <StyledSection>
        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.store_average_strength')}
          name="storeAverageStrength"
        >
          <InputNumber min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} label={t('clientDetails.store_max_strength')} name="storeMaxStrength">
          <InputNumber min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} label={t('clientDetails.store_coefficient')} name="storeCoefficient">
          <InputNumber min={0} step={0.1} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          label={t('clientDetails.store_weeks_strength')}
          name="storeNumberOfWeeksStrength"
        >
          <InputNumber min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item rules={[{ required: true }]} label={t('clientDetails.store_capacity_type')} name="storeCapacityType">
          <Select placeholder={t('clientDetails.select_capacity_type')} style={{ width: '100%' }}>
            {capacityTypes?.map((type) => (
              <Select.Option key={type} value={type}>
                {t(`clientDetails.${type}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </StyledSection>

      <div style={{ display: 'flex' }}>
        <label style={{ paddingRight: '10px' }}>{t('clientDetails.erp_master_of_creation_and_modification')}</label>
        <Form.Item
          name="isErpMasterOfCreationAndModification"
          valuePropName="checked"
          style={{ display: 'flex', justifyContent: 'center', width: '70px' }}
        >
          <StyledSwitch id="isErpMasterOfCreationAndModification" style={{ position: 'relative', top: '-5px' }} />
        </Form.Item>
      </div>

      <div style={{ display: 'flex' }}>
        <label style={{ paddingRight: '10px' }}>{t('clientDetails.erp_master_of_creation_only')}</label>
        <Form.Item valuePropName="checked" style={{ display: 'flex', justifyContent: 'center', width: '70px' }}>
          <StyledSwitch
            style={{ position: 'relative', top: '-5px' }}
            checked={masterOfCreationOnly}
            onChange={() => {
              setMasterOfCreationOnly(!masterOfCreationOnly);
              form.setFieldValue(
                'isErpMasterOfCreationAndModification',
                !form.getFieldValue('isErpMasterOfCreationAndModification'),
              );
            }}
          />
        </Form.Item>
      </div>

      <PrimaryButton
        textKey="common.save"
        type="primary"
        htmlType="submit"
        disabled={!form.isFieldsTouched || isSubmitButtonDisabled}
      />
    </Form>
  ) : (
    <Spinner size="large" />
  );
};

export default OptistockManagement;
