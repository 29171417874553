import React, { useState, useContext, useEffect } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Input } from 'antd';
import { MappingContext } from 'context';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PrimaryButton } from 'components/UI';
import { useCreateUniverseMutation } from 'services/graphql/main';
import { useError } from 'services/utils';

import MappingTool from './MappingTool';
import ProductCustomFieldsSection from './ProductCustomFields';
import StoreCustomFieldsSection from './StoreCustomFields';
import { StyledLabel, MappingUniverseSection, StyledSwitch, SubSectionTitle } from './styles';

const DataMapping: React.FC<{
  tenantId: number;
}> = ({ tenantId }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const {
    setCreatedUniverse,
    universeAvailable,
    setUniverseAvailable,
    assignSizeSystemFromFamily,
    setAssignSizeSystemFromFamily,
  } = useContext(MappingContext);

  const [createUniverse, { data }] = useCreateUniverseMutation({
    onError: (err) => addError(err, 'error'),
  });

  const [universe, setUniverse] = useState<{ code: string; name: string }>();
  const [productCustomFieldsOpen, setProductCustomFieldsOpen] = useState(false);
  const [storeCustomFieldsOpen, setStoreCustomFieldsOpen] = useState(false);

  const handleCreateUniverse = async () => {
    if (universe?.name && universe?.code) {
      await createUniverse({
        variables: {
          universeCreateInput: {
            code: universe.code,
            name: universe.name,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.createUniverse.id && data?.createUniverse.code && data?.createUniverse.name) {
      const msg = t('clientDetails.universe_created');
      toast.success(msg, { theme: 'colored' });
      setCreatedUniverse({ code: data?.createUniverse.code, name: data?.createUniverse.name });
      setUniverse(undefined);
    }
  }, [data, t, setCreatedUniverse]);

  return (
    <>
      <Row>
        <Col md={12}>
          <StyledLabel htmlFor="universe_code_available">{t('clientDetails.csv_file_contain_universe')}</StyledLabel>
          <StyledSwitch
            id="universe_code_available"
            checked={universeAvailable}
            onChange={(value) => setUniverseAvailable(value)}
          />
          {!universeAvailable && (
            <MappingUniverseSection>
              <div>
                <StyledLabel htmlFor="universe_code">{t('clientDetails.universe_code')}</StyledLabel>
                <Input
                  id="universe_code"
                  onChange={(e) =>
                    setUniverse((prevState) =>
                      prevState ? { ...prevState, code: e.target.value } : { code: e.target.value, name: '' },
                    )
                  }
                />
              </div>
              <div>
                <StyledLabel htmlFor="universe_name">{t('clientDetails.universe_name')}</StyledLabel>
                <Input
                  id="universe_name"
                  onChange={(e) =>
                    setUniverse((prevState) =>
                      prevState ? { ...prevState, name: e.target.value } : { name: e.target.value, code: '' },
                    )
                  }
                />
              </div>
              <PrimaryButton
                textKey="common.save"
                style={{ marginTop: 23 }}
                onClick={handleCreateUniverse}
                disabled={!universe?.code || !universe?.name}
              />
            </MappingUniverseSection>
          )}
        </Col>
        <Col md={12}>
          <StyledLabel htmlFor="assign_size_systems">{t('clientDetails.assign_size_systems_family')}</StyledLabel>
          <StyledSwitch
            id="assign_size_systems"
            checked={assignSizeSystemFromFamily}
            onChange={(value) => setAssignSizeSystemFromFamily(value)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <SubSectionTitle
            onClick={() => setProductCustomFieldsOpen(!productCustomFieldsOpen)}
            style={{ cursor: 'pointer' }}
          >
            {productCustomFieldsOpen ? <DownOutlined /> : <RightOutlined />} {t('clientDetails.custom_fields_product')}
          </SubSectionTitle>
          {productCustomFieldsOpen && <ProductCustomFieldsSection tenantId={tenantId} />}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <SubSectionTitle
            onClick={() => setStoreCustomFieldsOpen(!storeCustomFieldsOpen)}
            style={{ cursor: 'pointer' }}
          >
            {storeCustomFieldsOpen ? <DownOutlined /> : <RightOutlined />} {t('clientDetails.custom_fields_store')}
          </SubSectionTitle>
          {storeCustomFieldsOpen && <StoreCustomFieldsSection tenantId={tenantId} />}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <SubSectionTitle>{t('clientDetails.mapping_tool')}</SubSectionTitle>
        </Col>
        <Col md={12}>
          <SubSectionTitle>{t('clientDetails.file_mapping_status')}</SubSectionTitle>
        </Col>
        <Col md={24}>
          <MappingTool />
        </Col>
      </Row>
    </>
  );
};

export default DataMapping;
